import React from 'react'

import * as SEO from 'components/SEO'
import { useLocaleRedirection } from '@arch-log/webapp.lib/react.hook'

/**
 */
const Page = (props) => {
  useLocaleRedirection('/')
  return <SEO.Default />
}

/**
 */
export default Page
